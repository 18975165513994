.card-download {
  padding: 10px;
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
  text-align: center;
  color: #b1b1b1;
}

.card-download-container {
  border: 2px solid #ddb8b8;  
  background-color: #faf2f2;
  border-radius: 10px;
  margin: 0;
  padding: 20px;
}

.card-upload {
  padding: 10px;
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
  text-align: center;
  color: #b1b1b1;
}

.card-upload-container {
  border: 3px dashed #ddb8b8;  
  background-color: #faf2f2;
  border-radius: 10px;
  margin: 0;
  padding: 50px 50px 30px 50px;
}

/* .btn-choose {
  margin-left: 10px;
} */

/* ************** */

.card-gen-invoice {
  padding: 10px;
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
  text-align: center;
  color: #b1b1b1;
}

.card-gen-invoice-container {
  border-radius: 10px;
  margin: 0;
  padding: 50px
}

@media only screen and (max-width: 400px) {
  .card-upload-container {
    padding: 30px
  }
}

.css-17vdz66 {
  width: 100%;
}

.download-template {
  font-size: 12px;
  color: #898989;
  text-decoration-color: rgb(174 174 174 / 40%);
}